import React from 'react';
import './SponsorInfo.css';
import PageWrapper from './components/layout/PageWrapper';
import { Button, Col, Row } from 'react-bootstrap';
import LevelBlock from './components/sponsor-info/LevelBlock';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import HeadManipulator from './components/util/HeadManipulator';


function SponsorInfo() {

	return (

		<PageWrapper>
			<HeadManipulator pageName='Sponsor Info' canonicalPath='/sponsor-info' />
			<div className="SponsorInfo">

				<h1>Become a Sponsor!</h1>

				<Row>
					<Col >
						<p>
							The <a href="https://www.winterhavenoptimistclub.org/" target='_blank' rel="noreferrer">Winter Haven  Optimist Club <OpenInNewIcon /></a> is proud to announce that its ninth annual Santa&apos;s Run Fest
							is planned for <strong>Saturday, December 14, 2024</strong>. Last year, we had nearly 400 runners. This year&apos;s race includes a 10k run, 5k run/walk, and various divisions of kids' sprints.
							In addition, we have a special 5K competition race for family, social/church/school, and corporate teams. We would love to have your group participate!
						</p>
						<p>
							Your sponsorship for this event will be used to help fund youth projects, such as...
							<ul>
								<li>Scholarships for local school graduates</li>
								<li>Oratorical and essay contests</li>
								<li>Elementary school socks and underwear project</li>
								<li>Donations for homeless high-school students</li>
								<li>School supplies drive</li>
								<li>Various donations to additional youth and school programs</li>
							</ul>
						</p>
						<p>
							Visit the <a href="https://www.winterhavenoptimistclub.org/" target='_blank' rel="noreferrer">Winter Haven Optimist Club web site <OpenInNewIcon /></a> for more information.
						</p>
						<p>
							Please complete sponsor registration form using instruction below and show our community
							you support our schools and youth. Thank you!
						</p>
					</Col>
				</Row>

				<h3>Our sponsors are so special that we...</h3>
				<ul>

					<li>Announce each sponsor&apos;s name during the race.</li>
					<li>Display each sponsor&apos;s name at the start/finish line.</li>
					<li>Place each sponsor&apos;s name in a program for runners.</li>
					<li>Print each sponsor&apos;s logo or name on race shirts.</li>
					<li>Promote each sponsor on our website through December 1, 2025.</li>
					<li>Provide a vendor site for the sponsor (if requested).</li>
					<li>Tell our friends that you support our local youth!</li>
				</ul>

				<h2>Sponsorship Levels</h2>

				<div>
					<Row>
						<Col className='col-12 col-md-6 col-lg-4'>
							<LevelBlock name='Olympic' amount={1500} participants={12}>
								<li>All the benefits listed of a sponsor, plus...</li>
								<ul>
									<li>Extra-large logo on website with highest-priority placement.</li>
									<li>Extra-large logo on race shirts.</li>
									<li>Special emphasis in race-day announcements.</li>
								</ul>
							</LevelBlock>
						</Col>

						<Col className='col-12 col-md-6 col-lg-4'>
							<LevelBlock name='Elite' amount={1000} participants={8}>
								<li>All the benefits listed of a sponsor, plus...</li>
								<ul>
									<li>Large logo on website with priority placement.</li>
									<li>Large logo on race shirts.</li>
								</ul>
							</LevelBlock>
						</Col>

						<Col className='col-12 col-md-6 col-lg-4'>
							<LevelBlock name='Champion' amount={750} participants={6}>
								<li>All the benefits listed of a sponsor, plus...</li>
								<ul>
									<li>Medium logo on website.</li>
									<li>Medium logo on race shirts.</li>
								</ul>
							</LevelBlock>
						</Col>

						<Col className='col-12 col-md-6 col-lg-4'>
							<LevelBlock name='Marathon' amount={500} participants={4}>
								<li>All the benefits listed of a sponsor, plus...</li>
								<ul>
									<li>Small logo on website.</li>
									<li>Small logo on race shirts.</li>
								</ul>
							</LevelBlock>
						</Col>

						<Col className='col-12 col-md-6 col-lg-4'>
							<LevelBlock name='Sprinter' amount={250} participants={2}>
								<li>All the benefits listed of a sponsor, plus...</li>
								<ul>
									<li>Sponsor name on website.</li>
									<li>Sponsor name on race shirts.</li>
								</ul>
							</LevelBlock>
						</Col>
					</Row>
				</div>

				<h2 className="mt-3">Sponsor Instructions</h2>
				<Row>
					<Col xs={12}>
						<ol>
							<li>
								Submit the online sponsor registration form.
								<p>
									<Button><a target="_blank" href="https://forms.gle/ngVPHy9rnUdrnXCk7" rel="noreferrer">Sponsor Form <OpenInNewIcon /></a></Button>
								</p>
							</li>
							<li>Send donation check.</li>
							<li>Email your company logo to <a href="mailto:logo@mailgun.bywire.net?subject=Company+logo+submission" target="_blank" rel="noreferrer">logo@mailgun.bywire.net</a> to be
								displayed on web site and race tech shirt if Marathon Sponsor ($500) or above. We'll also be happy to answer any questions you may have about it.</li>
						</ol>
						<p><em>All sponsor materials (form, payment, and logo submission) must be completed by Friday, November 8, 2024, to be included on the race shirts!</em></p>

						<h5>Payment Information</h5>
						<p>Make checks payable to <strong>Optimist International Foundation (OIF)</strong>. This is a 501(c)(3) charitable organization. For tax processing by OIF, the minimum donation amount is $100.</p>

						<ul className="highlight">
							<li>For a charitable donation & tax processing the minimum donation is $100.</li>
							<li>Check Payable to: Optimist International Foundation (OIF)501(C)(3) Organization</li>
							<li>We also accept nontax processed checks to: Winter Haven Optimist Club</li>
						</ul>

						<p>
							Mail checks to:<br />
							<address className='code'>
								Perry Albritton, Race Chairman<br />
								Winter Haven Optimist Club<br />
								P.O. Box 7586<br />
								Winter Haven, FL 33883-7586
							</address>
						</p>

					</Col>
				</Row>

				<h2>Questions?</h2>
				<p>
					Call or text Rena at <a href="tel:863-604-7217" target="_blank" rel="noreferrer">863-604-7217 <OpenInNewIcon /></a>,
					or email <a href="mailto:info@mailgun.bywire.net" target="_blank" rel="noreferrer">info@mailgun.bywire.net  <OpenInNewIcon /></a>.
				</p>



			</div >

		</PageWrapper >
	);
}

export default SponsorInfo;

